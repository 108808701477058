import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import { useTheme } from '@mui/material/styles';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { Box, Container, Typography, useMediaQuery } from '@mui/material';

// eslint-disable-next-line
export const PrivacyPolicyTemplate = ({
	id,
    html,
    heading
}) => {

    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('sm'))

	return (
		<div>
			<SEO
				title="Play Retail Ltd | Retail Design Agency | London"
				description="We’re Play, a strategic retail design agency who utilises technology to deliver experiential shopper experiences, disrupting the future of retail. "
				image={`${id}.jpg`}
			/>
            <Container sx={{ px: mobile ? 2 : 8, pb: 8, pt: 12 }}>
                <Box sx={{ py: 4 }}>
                    <Typography component={"h1"} sx={{ fontSize: '2.5rem', fontWeight: '400', color: '#EA5758'}}>{heading}</Typography>
                </Box>
                <Box sx={{
					"& > ol": {
						fontSize: '1.5rem',
						fontWeight: 800
					}
				}} dangerouslySetInnerHTML={{ __html: html }}></Box>
            </Container>
		</div>
	);
};

const PrivacyPolicy = ({ data }) => {
	const { frontmatter } = data.markdownRemark;

	return (
		<Layout>
			<PrivacyPolicyTemplate html={data?.markdownRemark?.html} heading={frontmatter?.heading}/>
		</Layout>
	);
};

PrivacyPolicyTemplate.propTypes = {
	title: PropTypes.string,
	helmet: PropTypes.object
};

export default PrivacyPolicy;

export const pageQuery = graphql`
	query PrivacyPolicy {
		markdownRemark(frontmatter: { templateKey: { eq: "privacy-policy" } }) {
            id
            html
            frontmatter {
                heading
				footer {
					email
					phone
					address
					lineOne
					lineTwo
					linkOne
					linkTwo
					copyright
				}
            }
		}
	}
`;
